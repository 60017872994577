<template>
  <div class="project newProject" :class="{ 'isEdit': isEdit }">
    <h4 v-if="!isEdit">
      Crea un progetto ed assegnalo a una o più campagne impostando i parametri
    </h4>
    <div>
      <div class="filters-container pt">
        <div class="project-icon">
          <font-awesome-icon icon="tag" class="tag fa-xl" style="min-width: 40px" />
          <Multiselect
            v-model="filters.projectName"
            :disabled="filters.projectName[0]"
            mode="tags"
            :max="1"
            :can-clear="true"
            :close-on-select="true"
            :clear-on-select="true"
            :clear-on-blur="true"
            :searchable="true"
            :create-option="true"
            :options="tags"
            placeholder="Nome del progetto. Es. &quot;TalentWeek2023&quot;"
            no-options-text="Scrivi il nome del progetto e premi enter..."
            @create="handiveTagCreate"
          >
            <template #tag="{ option, handiveTagRemove }">
              <div class="multiselect-tag" :class="{ 'duplicate': duplicateName }" @click="handiveTagRemove(option, $event)">
                <span>{{ option.value }}</span>
              </div>
            </template>
          </Multiselect>
          <font-awesome-icon v-show="filters.projectName[0] && !isEdit" icon="xmark" class="close" @click="filters.projectName = []; tags = []; duplicateName = false" />
        </div>
        <div v-if="!isEdit">
          <input v-model="filters.published" type="checkbox" :checked="filters.published" @click="filters.published = !filters.published">
          <span style="font-size:11px;text-transform:uppercase">Pubblica il progetto dopo la creazione</span>
        </div>
      </div>
      <div class="filters-container">
        <material-date-picker :date="filters.startDate" label="Data di inizio" :bordered="true" :required="false" @update:date="filters.startDate = $event; filters.startDate > filters.endDate ? filters.endDate = filters.startDate : ''" />
        <material-date-picker
          :date="filters.endDate"
          label="Data di fine"
          :disabled="!filters.startDate"
          :bordered="true"
          :required="false"
          :lower-limit="filters.startDate"
          @update:date="filters.endDate = $event"
        />
      </div>
      <div class="filters-container">
        <Multiselect
          :key="filters.company ? 'azienda_coid_' + filters?.company?.coid : 'no_selection'"
          v-model="filters.company"
          placeholder="Cerca l'azienda"
          :object="true"
          :filter-results="false"
          :min-chars="1"
          :resolve-on-load="false"
          :delay="250"
          :searchable="true"
          label="co_name"
          track-by="coid"
          value-prop="coid"
          :clear-on-select="true"
          :no-options-text="noResults ? 'Nessuna azienda corrisponde alla ricerca effettuata' : 'Inizia a digitare...'"
          :options="async (query) => await searchCompany(query)"
          @select="getCampaigns"
        />
        <div style="width:50%" />
      </div>
      <div v-if="filters?.company && campaignsOpt?.data?.length > 0" class="tabs">
        <div class="projectByCoid-container">
          <button class="projectByCoid " :class="{ 'allthecompaniesEnabled right': !filters.projectByCoid }" @click="filters.projectByCoid = false">
            Assegna per campagna
          </button>
          <button class="projectByCoid" :class="{ 'allthecompaniesEnabled left': filters.projectByCoid }" @click="filters.projectByCoid = true">
            Assegna per azienda
          </button>
        </div>

        <div class="filters-container" :style="{ 'flex-direction': filters.projectByCoid ? 'column' : 'row' }">
          <div v-if="!filters.projectByCoid" class="filters-container-alert manageCampaign">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>plus-circle</title>
              <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
            <span>Seleziona una o più campagne da associare al progetto.</span>
          </div>
          <div v-else class="filters-container-alert removeCampaign">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>minus-circle</title>
              <path d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
            <span>Tutte le campagne presenti e future delle aziende selezionate sono automaticamente associate al progetto. <p>Per escludere una o più campagne, selezionane le checkbox.</p></span>
          </div>
          <div v-if="filters.projectByCoid" class="addAllBtn">
            <material-button
              :text="dynamicButtonText()"
              class="pure-material-button-contained info small"
              @click="addCompaniesSelected"
            />
            <!-- <button @click="addCompaniesSelected">
              <span v-if="filters.campaignsExcluded.length > 0">
                Aggiungi l'azienda {{ filters.company?.co_name }} con {{ filters.campaignsExcluded.length }} {{ (filters.campaignsExcluded.length > 1) ? 'eccezioni' : 'eccezione' }} al progetto
              </span>
              <span v-else>Aggiungi l'azienda {{ filters.company?.co_name }} al progetto</span>
            </button> -->
          </div>
        </div>
        <campaignsTable
          v-if="filters?.company && campaignsOpt?.data?.length > 0"
          :key="'campaignsTable_' + filters.projectByCoid + campaignsOpt?.data?.length"
          :correnti="filters.correnti"
          :archiviate="filters.archiviate"
          :scadute="filters.scadute"
          :exceptions="filters.projectByCoid"
          :total-pages-items="totalPages"
          :total-elements="totalElements"
          :page-size="itemsPerPage"
          :page-number="currentPage"
          :items="campaignsOpt.data"
          :selected-items="!filters.projectByCoid ? filters.companies : filters.campaignsExcluded"
          :campaign-title="campaignsOpt?.data[0]?.company"
          :coid="campaignsOpt?.data[0]?.coid"
          :final-preview="false"
          @update:correnti="($event) => { filters.correnti = $event; changeStatus('correnti') }"
          @update:archiviate="($event) => { filters.archiviate = $event; changeStatus('archiviate') }"
          @update:scadute="($event) => { filters.scadute = $event; changeStatus('scadute') }"
          @update:selected="filters.projectByCoid ? filters.companies = $event : filters.campaignsExcluded = $event"
          @update:page="(n) => getCampaigns({ page: n })"
          @searchByCoidOrTitle="(obj) => getCampaigns(obj)"
          @addCompaniesSelected="addCompaniesSelected"
        />
      </div>

      <div v-if="!(filters?.company?.coid) && (filters.companies && filters.companies.length > 0) || filters.allTheCompanies && filters.allTheCompanies.length > 0" class="finalAccordion box" role="presentation">
        <!--  Il listato delle campagne associate al progetto -->
        <div v-if="filters.companies && filters.companies.length > 0" class="accordion-item" :class="{ 'is-active': preview.campaigns }">
          <div class="accordion-item-header" @click="toggle('campaigns')">
            <span class="accordion-item-header-element chips"><span>{{ filters?.companies?.length === 1 ? '1 campagna associata al progetto' : filters?.companies?.length + ' campagne associate al progetto' }}</span></span>
            <span class="accordion-item-header-element" />
            <span class="accordion-item-trigger-icon" />
          </div>
          <transition name="accordion-item" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
            <div v-if="preview.campaigns" class="accordion-item-details">
              <div class="accordion-item-details-inner">
                <campaignsTable
                  v-if="!filters?.company?.coid && filters.companies.length > 0"
                  :items="filters.companies"
                  :selected-items="filters.companies"
                  :final-preview="true"
                  :is-edit="isEdit"
                  @updateRemove="removeItem($event, 'campaignsSelection')"
                />
              </div>
            </div>
          </transition>
        </div>
        <!--  Il listato delle aziende associate al progetto -->
        <div v-if="filters.allTheCompanies && filters.allTheCompanies.length > 0" class="accordion-item" :class="{ 'is-active': preview.companies }">
          <div class="accordion-item-header" @click="toggle('companies')">
            <span class="accordion-item-header-element chips" style="flex: none;">
              <span v-if="filters.allTheCompanies.length === 1">
                {{ filters.allTheCompanies.length }} azienda associata al progetto
              </span>
              <span v-else>
                {{ filters.allTheCompanies.length }} aziende associate al progetto
              </span>
            </span>
            <span class="accordion-item-header-element chips"><span v-if="filters.campaignsExcluded.length > 0">{{ filters?.campaignsExcluded?.length === 1 ? '1 eccezione' : filters?.campaignsExcluded?.length + ' eccezioni associate al progetto' }}</span></span>
            <span class="accordion-item-trigger-icon" />
          </div>
          <transition name="accordion-item" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
            <div v-if="preview.companies" class="accordion-item-details">
              <div class="accordion-item-details-inner">
                <!-- altro accordion con listato aziende + eccezioni    -->
                <div class="accordion box custom-table" role="presentation">
                  <div v-for="company in filters.allTheCompanies" :key="company.coid" class="accordion-item" :class="{ 'is-active': coidOpen === company.coid }">
                    <div class="accordion-item-header" @click="() => { coidOpen === company.coid ? coidOpen = -1 : coidOpen = company.coid }">
                      <span>{{ company.coid }} - {{ company.co_name }} - {{ filters.campaignsExcluded.filter(f => f.coid === company.coid).length }} eccezioni</span>
                      <span v-show="filters.campaignsExcluded.filter(f => f.coid === company.coid).length" class="accordion-item-trigger-icon" />

                      <font-awesome-icon
                        class="deleteItem deleteCompany toDelete"
                        icon="trash-can"
                        @click="removeCompanyFromProject(company.coid)"
                      />
                    </div>
                    <transition name="accordion-item" @enter="startTransition" @after-enter="endTransition" @before-leave="startTransition" @after-leave="endTransition">
                      <div v-if="coidOpen === company.coid && filters.campaignsExcluded.filter(f => f.coid === company.coid).length > 0" class="accordion-item-details">
                        <div class="accordion-item-details-inner">
                          <campaignsTable
                            :items="filters.campaignsExcluded.filter(f => f.coid === company.coid)"
                            :selected-items="filters.campaignsExcluded"
                            :final-preview="true"
                            is-edit
                            @update:remove="removeCampaignFromProject($event)"
                          />
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="pt right">
        <material-button
          v-if="!isEdit"
          text="Crea progetto"
          class="pure-material-button-contained info small"
          :class="{
            'disabled-btn': !filters.projectName[0]
          }"
          @click="createProject"
        />
        <material-button v-if="isEdit" text="Modifica progetto" class="pure-material-button-contained info small" :class="{ 'disabled-btn': !filters.projectName[0] || filters.companies?.length === 0 }" @click="modifyProject" />
        <material-button
          v-if="isEdit"
          text="Cancella progetto"
          class="pure-material-button-contained error small"
          :class="{ 'disabled-btn': !filters.projectName[0] || filters.companies?.length === 0 }"
          style="background-color:  rgb(152, 23, 57)"
          @click="deleteProject"
        />
      </div>
    </div>
  </div>
</template>
<script>
import campaignsTable from './campaignsProjectTable.vue'
import { sbapibackoffice } from '@/api'
import { inject, watch, onMounted, onBeforeMount } from 'vue'
import { ref, reactive } from '@vue/reactivity'
import Multiselect from '@vueform/multiselect'
import '@vuepic/vue-datepicker/dist/main.css'
require('dayjs/locale/it')
import dayjs from 'dayjs'
import { spinnerStore } from '@/stores/spinner'
dayjs.locale('it')
import { navbarStore } from '@/stores/navbar'
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Rome')
export default {
  components: { Multiselect, campaignsTable },
  props: {
    projectSelected: {
      type: Array,
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['modifyProject', 'deleteProject'],
  setup(props, { emit }) {
    let tab = ref(1)
    let currentPage = ref(1)
    let itemsPerPage = 15
    let coidOpen = ref(-1)
    let totalPages = ref(0)
    let totalElements = ref(0)
    const duplicateName = ref(false)
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const showPreview = ref(false)
    const search = ref('')
    const selections = ref([])
    const toast = inject('$toast')
    const tags = ref([])
    const noResults = ref(false)
    const campaignsOpt = ref({})
    const counter = ref(1)
    const preview = reactive({
      campaigns: false,
      companies: false
    })
    const filters = reactive({
      published: true,
      projectName: [],
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().add(6, 'month').startOf('day').toDate(),
      projectByCoid: false,
      archiviate: false,
      scadute: false,
      correnti: true,
      company: {},
      companies: [],
      allTheCompanies: [],
      campaignsExcluded: []
    })
    watch(() => filters.company, () => {
      if (!filters.company || !filters?.company.coid) {
        campaignsOpt.value.data = {}
      }
    })
    onBeforeMount(() => {
      if (props.isEdit) {
        filters.projectName = props.projectSelected.projectName
        tags.value = props.projectSelected.projectName
        filters.startDate = props.projectSelected.startDate
        filters.endDate = props.projectSelected.endDate
        filters.companies = props.projectSelected.companies
      }
    })
    onMounted(() => {
      spinner.show()
      navbar.title = 'Crea progetto'
      spinner.hide()
    })
    const searchCompany = async (query) => {
      currentPage.value = 1
      showPreview.value = false
      noResults.value = false

      try {
        if (query) {
          let results = await sbapibackoffice.get('companieslist?initialLettersOfCompanies=' + query)
          if (results?.data[0]?.coid) {
            return results.data
          }
          noResults.value = true
          return []
        }
        return []
      } catch (error) {
        toast.error('Errore durante la ricerca dell\'azienda: ' + error.message)
        noResults.value = true
        return []
      }
    }
    const updateStatus = () => {
      const status = []
      if (filters.correnti) {
        status.push(1)
      }
      if (filters.scadute) {
        status.push(2)
      }

      if (filters.archiviate) {
        status.push(3)
      }
      console.log('status', status, status.toString())
      return status.toString()
    }
    const getCampaigns = async (obj) => {

      showPreview.value = false
      if (!filters?.company?.coid) {
        console.log('Nessun coid')
        return
      }

      spinner.show()

      if (obj && obj.page) {
        currentPage.value = Number(obj.page)
      }

      const status = updateStatus()
      const coid = filters.company.coid

      try {
        const query = `v2/campaigns?coid=${coid}&status=${status}${obj && obj.search ? (isNaN(obj.search) ? `&search=${obj.search}` : `&cid=${obj.search}`) : ''}&page=${Number(currentPage.value) - 1}&size=${itemsPerPage}&sort=dataCreazione,desc`

        const results = await sbapibackoffice.get(query)

        if (results?.data?.content?.length > 0) {
          totalElements.value = results.data.totalElements
          totalPages.value = results.data.totalPages

          results.data.content.forEach(content => {
            content.coid = coid
            content.company = filters.company.co_name
          })

          campaignsOpt.value.data = results.data.content

          if (Number(currentPage.value) === 1 && !obj?.page && !obj?.search) {
            const totalCampaigns = results.data.totalElements
            toast.success(`Trovate ${totalCampaigns} ${totalCampaigns === 1 ? 'campagna' : 'campagne'}`)
          }
        } else {
          await searchCompany('')

          if (obj.search) {
            toast.warning(`Nessuna campagna trovata per la ricerca "${obj.search}"`)
          } else {
            toast.warning('Nessuna campagna trovata per l\'azienda selezionata')
            campaignsOpt.value = {}
            filters.company = ''
          }
        }
      } catch (err) {
        toast.error(`Impossibile recuperare i dati della campagna: ${err}`)
      }

      spinner.hide()
    }

    const removeItem = (element, where) => {
      if (where === 'campaignsSelection') {
        let index = filters.companies.findIndex(e => e.cid === element.cid)

        if (index !== -1) {
          filters.companies.splice(index, 1)
        }
      }
      else {
        let index = filters.campaignsExcluded.findIndex(e => e.cid === element.cid)
        if (index !== -1) {
          filters.campaignsExcluded.splice(index, 1)
        }
      }

    }

    const addCompaniesSelected = () => {
      var cids = null
      if (!filters.projectByCoid) {
        cids = new Set(filters.companies.map(d => d.cid))
        filters.companies = [...filters.companies, ...selections.value.filter(d => !cids.has(d.cid))]
        console.log(filters.companies)
      } else {
        let findCompany = filters.allTheCompanies.find(c => c.coid === filters.company.coid)
        if (!findCompany) {
          filters.allTheCompanies.push(filters.company)
        }
        cids = new Set(filters.campaignsExcluded.map(d => d.cid))
        filters.campaignsExcluded = [...filters.campaignsExcluded, ...selections.value.filter(d => !cids.has(d.cid))]
        console.log(filters.campaignsExcluded)
      }
      selections.value = []
      filters.company = {}
      campaignsOpt.value = {}
    }
    const isChecked = (cid) => {
      return filters.companies.some(a => a.cid === cid) || selections.value.some(a => a.cid === cid)
    }
    const resetProject = () => {
      filters.published = true
      filters.projectName = []
      filters.startDate = dayjs().subtract(6, 'month').startOf('day').toDate()
      filters.endDate = dayjs().startOf('day').toDate()
      filters.archiviate = false
      filters.scadute = false
      filters.correnti = true
      filters.company = {}
      filters.companies = []
      tags.value = []
      selections.value = []
      campaignsOpt.value = {}

      // empty tables
      filters.allTheCompanies = []
      filters.campaignsExcluded = []
    }
    const deleteProject = () => {
      console.log('Cancella progetto')
      emit('deleteProject')
    }
    const modifyProject = () => {
      emit('modifyProject', filters)
    }
    const createProject = async () => {
      duplicateName.value = false
      if (!filters.projectName[0]) {
        toast.warning('Inserisci il nome del progetto')
        return
      }

      const excludedCompanies = filters.allTheCompanies.map(c => {
        return {
          coid: c.coid,
          excludeCids: filters.campaignsExcluded.filter(f => f.coid === c.coid).map(c => c.cid)
        }
      })

      if (filters.companies?.length === 0 && excludedCompanies?.length === 0) {
        toast.warning('Seleziona almeno una campagna per procedere con la creazione del progetto')
        return
      }
      spinner.show()

      const params = {
        name: filters.projectName[0],
        startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
        cids: filters.companies.map(c => c.cid),
        published: filters.published,
        companies: excludedCompanies
      }

      // if params companies excludeCids contains same cid of params cids toast error
      if (params.companies.length > 0) {
        params.companies.forEach(c => {
          if (c.excludeCids.length > 0) {
            c.excludeCids.forEach(cid => {
              if (params.cids.includes(cid)) {
                toast.error('Non puoi escludere una campagna che hai già selezionato')
                spinner.hide()
                return
              }
            })
          }
        })
      }

      sbapibackoffice
        .post('/projects', params)
        .then(() => {
          toast.success('Progetto creato con successo')
          resetProject()
        })
        .catch(err => {
          toast.clear()
          if (err.response && err.response.data) {
            let messageCode = err.response.data.messageCode
            let errorMessage = 'Si è verificato un errore: '
            if (err.response.status === 406) {
              switch (messageCode) {
                case 'PRJ-001':
                  errorMessage += 'il nome del progetto deve essere specificato'
                  break
                case 'PRJ-002':
                  errorMessage += 'la data di inizio del progetto deve essere specificata'
                  break
                case 'PRJ-003':
                  errorMessage += 'la data di fine del progetto deve essere specificata'
                  break
                case 'PRJ-004':
                  errorMessage += 'la data di inizio deve essere precedente alla data di fine del progetto'
                  break
                case 'PRJ-005':
                  errorMessage += 'esiste già un progetto con il nome ' + filters.projectName[0]
                  duplicateName.value = true
                  break
                case 'PRJ-006':
                  errorMessage += 'alcuni dei cid da assegnare al progetto non esistono'
                  break
                case 'PRJ-009':
                  errorMessage += 'sono presenti campagne (cid) duplicate'
                  break
                case 'PRJ-010':
                  errorMessage += 'sono presenti aziende (coid) duplicate in input'
                  break
                case 'PRJ-011':
                  errorMessage += 'sono presenti cid in comune tra quelli da aggiungere e escludere dal progetto'
                  break
                case 'PRJ-012':
                  errorMessage += 'alcuni dei coid da assegnare al progetto non esistono'
                  break
                case 'PRJ-013':
                  errorMessage += 'alcuni dei cid da escludere dall\'azienda legata al progetto non esistono'
                  break
              }
            }
            toast.error(errorMessage)
          } else {
            toast.error('Si è verificato un errore')
          }
        })
        .finally(() => {
          spinner.hide()
        })
    }

    const changeStatus = (input) => {
      if (!filters.correnti && !filters.archiviate && !filters.scadute) {
        filters[input] = true
        counter.value++
        toast.warning('Seleziona almeno una tipologia di status per la ricerca delle campagne')
      }
      else {
        getCampaigns()
      }

    }

    const handiveTagCreate = (option, select$) => {
      if (option && option.label) {
        spinner.show()
        sbapibackoffice
          .get('/projects/check?name=' + option.label)
          .then(response => {
            if (response.data.exists) {
              toast.error('Il nome progetto già esiste')
              setTimeout(() => {
                filters.projectName = []
                select$.clear()
                select$.close()
              }, 50)
              return
            } else {
              tags.value.push(option)
              setTimeout(() => {
                select$.select(option)
                select$.close()
              }, 50)
            }
          })
          .catch(err => {
            toast.error(err)
            console.log(err)
          })
          .finally(() => {
            select$.search = ''
            spinner.hide()
          })
      }
    }

    const startTransition = (el) => {
      el.style.height = el.scrollHeight + 'px'
    }

    const endTransition = (el) => {
      el.style.height = ''
    }
    const toggle = (el) => {
      console.log(el)
      if (preview[el]) {
        preview[el] = false
      } else {
        preview.campaigns = false
        preview.companies = false
        preview[el] = true
      }
    }

    const dynamicButtonText = () => {
      if (filters.campaignsExcluded.length > 0) {
        return `Aggiungi l'azienda ${filters.company?.co_name} con ${filters.campaignsExcluded.length} ${(filters.campaignsExcluded.length > 1) ? 'eccezioni' : 'eccezione'} al progetto`
      }
      return `Aggiungi l'azienda ${filters.company?.co_name} al progetto`
    }

    const removeCompanyFromProject = (coid) => {
      let index = filters.allTheCompanies.findIndex(e => e.coid === coid)
      if (index !== -1) {
        filters.allTheCompanies.splice(index, 1)
      }
    }

    const removeCampaignFromProject = ({ cid }) => {
      let index = filters.campaignsExcluded.findIndex(e => e.cid === cid)
      if (index !== -1) {
        filters.campaignsExcluded.splice(index, 1)
      }
    }

    return { removeCampaignFromProject, removeCompanyFromProject, dynamicButtonText, coidOpen, toggle, startTransition, endTransition, preview, handiveTagCreate, currentPage, itemsPerPage, totalPages, totalElements, duplicateName, changeStatus, counter, createProject, modifyProject, deleteProject, removeItem, noResults, showPreview, isChecked, addCompaniesSelected, search, selections, getCampaigns, campaignsOpt, tags, filters, searchCompany, tab }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@use "../assets/scss/projects";

.tabs {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;

  .projectByCoid-container {
    gap: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .projectByCoid {
      cursor: pointer;
      padding: 7.5px 15px;
      position: relative;
      min-height: 40px;
      flex-direction: column;
      gap: 2.5px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background-color: rgba(0, 0, 0, 0.2);
      flex: 1;
      border: 0;
      outline: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      text-transform: uppercase;
      text-transform: small-caps;
      text-align: left;

      &.allthecompaniesEnabled {
        color: white;
        background-color: transparent;
        border-bottom: 0;

        &.left {
          border-left: 1px solid rgba(255, 255, 255, 0.5);
        }

        &.right {
          border-right: 1px solid rgba(255, 255, 255, 0.5);
        }
      }

      p.projectByCoid-subtext {
        font-size: 12px;
        color: white;
        text-transform: none;
        font-variant: none;
      }
    }
  }

  .filters-container-alert {
    display: flex;
    align-items: center;
    margin: 15px 10px 5px 10px;
    border-radius: 4px;
    padding: 0.7rem 1rem !important;
    width: calc(100% - 20px);
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    text-transform: small-caps;

    span {
      margin-left: 10px;
    }

    svg {
      width: 25px;
      height: auto;
    }

    &.manageCampaign {
      color: #c9f8ff !important;
      background: rgba(33, 223, 253, 0.25) !important;

      svg path {
        fill: #17a2b8;
      }
    }

    &.removeCampaign {
      color: #fbe4e5 !important;
      background: rgba(255, 35, 130, 0.35);

      svg path {
        fill: #ff6188 !important;
      }
    }
  }
}
.newProject{
  .multiselect-caret {
    z-index: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
.newProject {
  .finalAccordion {
    .accordion-item {
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.5);

    }

    .accordion-item-header {
      position: relative;
      color: white;
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 40px;
      border-bottom: 1px solid white;

      .accordion-item-header-element {
        padding-right: 10px;
        display: flex;

        &.chips {
          align-items: center;

          span {
            background-color: #118294;
            font-variant: small-caps;
            color: white;
            border-radius: 4px;
            width: fit-content;
            padding: 2.5px 10px;
          }
        }

      }

      .accordion-item-trigger {
        width: 100%;
        text-align: left;
        background-color: transparent;
        border: none;
      }

      .accordion-item-trigger-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 1.25rem;
        bottom: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: translateY(-2px) rotate(45deg);
        transition: transform 0.2s ease;
      }

    }

    .accordion-item {
      .accordion-item-header:last-child {
        border-bottom: 0;
      }

      &.is-active .accordion-item-trigger-icon {
        transform: translateY(2px) rotate(225deg);
      }
    }

  }

  .accordion-item-details {
    overflow: hidden;
  }

  .accordion-item-enter-active,
  .accordion-item-leave-active {
    will-change: height;
    transition: height 0.2s ease;
  }

  .accordion-item-enter,
  .accordion-item-leave-to {
    height: 0 !important;
  }

  .accordion-item-trigger {
    padding: 0.75rem 1.25rem;
  }

  .deleteItem {
    cursor: pointer;
    width: 16px;
    height: auto;

    &.deleteCompany {
      margin-left: auto;
    margin-right: 30px;
    }

    path {
      fill: whitesmoke;
    }
    &:hover{
      &.toDelete {
        path {
          transition: all 450ms ease-in;
          fill: var(--danger);
        }
      }
    }
  }
}

.custom-table {
  .accordion-item {
    margin-bottom: 0px !important;
    border-radius: 0px !important;
    border-color: #fff4 !important;
    .accordion-item-header {
      border-bottom: 0px !important;
      border-color: #fff4 !important;
    }
  }
}
</style>